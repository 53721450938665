import { Button, Divider, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { SdiColumnType, SdiMapping } from './types';

interface MappingFormProps {
  initialMapping?: SdiMapping[];
}

const typeToLabel = (type: SdiColumnType) => {
  switch (type) {
    case SdiColumnType.Date:
      return 'Date';
    case SdiColumnType.Money:
      return 'Float';
    case SdiColumnType.Float:
      return 'Double Precision';
    case SdiColumnType.Number:
      return 'Integer';
    default:
      return type;
  }
};

export const MappingForm = ({ initialMapping }: MappingFormProps) => {
  const initialMappingValues =
    initialMapping?.map((mapping) => ({
      sourceColumnName: mapping.source.columnName,
      targetFieldName: mapping.target.dbFieldName,
      targetFieldType: mapping.target.type,
    })) ?? [];

  return (
    <Form.List name={'mapping'} initialValue={initialMappingValues}>
      {(mappings, { add, remove }) => {
        return (
          <div>
            <Divider />
            {mappings.map((mapping, index) => (
              <div key={mapping.key}>
                <Form.Item
                  name={[index, 'sourceColumnName']}
                  label={'Source column name'}
                  rules={[{ required: true, message: 'Please input source column name' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={'DB field type'}
                  name={[index, 'targetFieldType']}
                  rules={[{ required: true, message: 'Please input target db field type' }]}
                >
                  <Select>
                    {Object.values(SdiColumnType).map((sdiColumnType) => (
                      <Select.Option key={sdiColumnType} value={sdiColumnType}>
                        {typeToLabel(sdiColumnType)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={'DB field name'}
                  name={[index, 'targetFieldName']}
                  rules={[
                    { required: true, message: 'Please input target db field name' },
                    {
                      pattern: /^[a-zA-Z0-9_]+$/,
                      message: 'Only letters, numbers and underscores are allowed',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {mappings.length > 1 ? (
                  <Button
                    type="link"
                    className="dynamic-delete-button"
                    onClick={() => remove(mapping.name)}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove mapping
                  </Button>
                ) : null}
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button type="dashed" onClick={() => add()} style={{ width: '60%' }}>
                <PlusOutlined /> Add mapping
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};
